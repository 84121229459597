
import { defineComponent, onMounted, ref } from "vue";
import Redacao from "@/interfaces/Redacao";
import BaseService from "@/services/admin/BaseService";

export default defineComponent({
  props: { id: { type: String, required: false } },
  setup(props) {
    const loading = ref<boolean>(false);
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const redacoes = ref<Redacao[]>();

    const getRedacoes = (): void => {
      loading.value = true;
      BaseService.list("aluno/minhas-redacoes")
        .then(({ data }) => {
          const redacoesArray: Redacao[] = data.reduce(
            (redacoes: any, item: Redacao) => {
              const redacao: any = redacoes[item.tipo_redacao.nome] || [];
              redacao.push(item);
              redacoes[item.tipo_redacao.nome] = redacao;
              return redacoes;
            },
            {}
          );
          redacoes.value = redacoesArray;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    onMounted(() => {
      getRedacoes();
    });

    return {
      loading,
      urlImg,
      redacoes,
      getRedacoes,
    };
  },
});
